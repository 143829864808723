import React, { useEffect, useRef } from 'react';
import { createChart, ColorType, CandlestickData, ChartOptions, DeepPartial } from 'lightweight-charts';

interface ICandlestickChart {
    data: CandlestickData[] | undefined;
}

const CandlestickChart = ({ data }: ICandlestickChart) => {

    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const colors = {
        backgroundColor: 'white',
        lineColor: '#2962FF',
        areaTopColor: '#2962FF',
        areaBottomColor: 'rgba(41, 98, 255, 0.28)',
        textColor: 'black'
    }

    const chartOptions: DeepPartial<ChartOptions> = {
        layout: {
            background: { type: ColorType.Solid, color: colors.backgroundColor },
            textColor: colors.textColor,
        },
        width: 0,
        height: 300,
        timeScale: {
            fixRightEdge: true, // to fix right edge of the time scale
            fixLeftEdge: true, // to fix left edge of the time scale
            allowBoldLabels: true, // to allow bold labels
        },
    }

    useEffect(() => {
        if (!chartContainerRef.current) return;

        const handleResize = () => {
            if (chartContainerRef.current) {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            }
        };

        const chart = createChart(chartContainerRef.current, chartOptions);

        const dateNow = Date.now()

        // inital view of the chart
        chart.timeScale().setVisibleLogicalRange({ from: dateNow / 1000 - 40, to: dateNow / 1000 });

        const newSeries = chart.addCandlestickSeries();

        newSeries.setData(data || []);

        window.addEventListener('resize', handleResize);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [data]);

    return (
        <div ref={chartContainerRef} />
    );
};

export default CandlestickChart;
