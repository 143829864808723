import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Pagination, Typography } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import NewsCard from '../card';
import { INews, useNews } from '../../../../hooks/news';
import { useContextNotifications } from '../../../../context/notifications';
import Loading from '../../../Common/Loading';
import { useContextUsers } from '../../../../context/users';

import './newsList.less';

interface INewsListProps {
  scrollToTop: () => void;
}

const NewsList = ({scrollToTop}:INewsListProps) => {
  const [news, setNews] = useState<INews[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(20);
  const { openNotification } = useContextNotifications();
  const { profile } = useContextUsers();
  const getNews = useNews();
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchNews(searchQuery);
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const fetchNews = (search = '') => {
    getNews
      .fetch({ page: currentPage, pageSize, searchQuery: search })
      .then((res) => {
        if (res?.data?.data) {
          const sortedNews = [...res.data.data].sort((a: INews, b: INews) => {
            const dateA = new Date(a.pubDate).getTime();
            const dateB = new Date(b.pubDate).getTime();

            return dateB - dateA;
          });

          setNews(sortedNews);
          setTotalRecords(res.data.totalRecords);
        }
      })
      .catch((error) => {
        openNotification?.({
          message: `Error while Getting News: ${error}`,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    fetchNews();
  }, [currentPage, pageSize]);

  const handleClick = (id: number | string) => {
    if (!!profile && profile.type === 'admin') {
      navigate(`/breaking-news/${id}`);
    }
  };

  const handlePageChange = (page: number, size?: number) => {
    setCurrentPage(page);
    if (size) {
      setPageSize(size);
    }
    scrollToTop() 
  };

  return (
    <>
      <div
        className="search-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Input.Search
          className="search-input"
          allowClear
          name="search"
          size="large"
          placeholder="Search news..."
          value={searchQuery}
          onPressEnter={() => fetchNews(searchQuery)}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: '30%' }}
        />
      </div>
      <Loading visible={getNews.loading} absolute />
      {news.length === 0 ? (
        !getNews.loading && (
          <div className="not-found-container">
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
              <FrownOutlined />
              <Typography.Title level={4}>No News Found</Typography.Title>
            </div>
            <p>Try adjusting your search or filter to find what you're looking for.</p>
          </div>
        )
      ) : (
        <>
          <div className="wrapper" style={{ display: 'grid' }}>
            {news.map((item: INews) => (
              <NewsCard key={item.id} news={item} handleClick={() => handleClick(item.id)} />
            ))}
          </div>
          <div className="pagination-container">
            <Pagination
              showSizeChanger
              onShowSizeChange={handlePageChange}
              onChange={handlePageChange}
              defaultCurrent={currentPage}
              total={totalRecords}
              defaultPageSize={pageSize}
              responsive
            />
          </div>
        </>
      )}
    </>
  );
};

export default NewsList;
