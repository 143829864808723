/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography } from 'antd';
import { ICurrentMarketRates } from '../../../hooks/reports';
import ContentCard from '../ContentCard';
import CandlestickChart from './chart';

interface IMarketRatesChart {
  title: string;
  data?: ICurrentMarketRates[];
  loading: boolean;
  height: number | string;
  reversed?: boolean;
  controls?: React.ReactNode;
}

const MarketRatesChart = ({ title, data, loading, height, reversed, controls }: IMarketRatesChart) => {
  const colorRed = getComputedStyle(document.documentElement).getPropertyValue('--color-tag-red');
  const colorGreen = getComputedStyle(document.documentElement).getPropertyValue('--color-tag-green');

  const getDifference = (value?: number, prevValue?: number) => {
    if (!value || !prevValue) return { diff: 0, color: 'inherit' };

    const diff = value - prevValue;
    const color = reversed ? (diff < 0 ? colorGreen : colorRed) : diff > 0 ? colorGreen : colorRed;

    return {
      diff:
        diff > 0
          ? `+${Math.abs(diff).toFixed(title === 'UMBS 30 Year - 5.5% Coupon' ? 2 : 3)}`
          : `-${Math.abs(diff).toFixed(title === 'UMBS 30 Year - 5.5% Coupon' ? 2 : 3)}`,
      color,
    };
  };

  return (
    <ContentCard bordered padding={20}>
      <div className="flex-col gap-12">
        <div className="flex-col gap-4">
          <div className="flex-row gap-20 flex-align-center flex-justify-space-between">
            <div className="flex-row gap-20">
              <Typography.Title level={2} style={{ color: '#464646' }}>
                {title}
              </Typography.Title>
              {data?.[0].close && data?.[1].close && (
                <div className="flex-row gap-4 flex-align-center">
                  <Typography.Text className="color-dark fs-1">
                    {data[data.length - 1].close.toFixed(title === 'UMBS 30 Year - 5.5% Coupon' ? 2 : 3)}
                  </Typography.Text>
                  <Typography.Text style={{ color: '#464646' }}>
                    ({getDifference(data?.[data.length - 1].close, data?.[data.length - 2].close).diff})
                  </Typography.Text>
                </div>
              )}
            </div>
            {controls}
          </div>
          {data && (
            <div className="flex-row gap-12">
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">Open: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].open.toFixed(3)}</Typography.Text>
              </div>
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">High: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].high.toFixed(3)}</Typography.Text>
              </div>
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">Low: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].low.toFixed(3)}</Typography.Text>
              </div>
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">Close: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].close.toFixed(3)}</Typography.Text>
              </div>
            </div>
          )}
        </div>
        <ContentCard loading={loading && !data}>
          <div style={{ margin: '0 -20px -20px' }}>
            <CandlestickChart data={data}/>
          </div>
        </ContentCard>
      </div>
    </ContentCard>
  );
};

MarketRatesChart.defaultProps = {
  data: undefined,
  reversed: false,
  controls: undefined,
};

export default MarketRatesChart;
