import { FetchResponse } from '../types';
import {
    DefaultFetchError,
    FetchCreate,
    FetchGet,
    useFetchCreate,
    useFetchGet,

} from './fetch';

export interface ICreateResponse {
    data: { message: string };
}

export interface ICreateViewParams {
    email?: string;
    reportId?: string;
    name: string;
    viewIp: string;
}

export interface IGetViewParams {
    reportId: string;
    viewIp: string;
}

export interface IGetResponse {
    alreadyViewed: boolean;
}

export const useUserViewCreate = ():
    FetchCreate<FetchResponse<ICreateResponse>, DefaultFetchError, ICreateViewParams> =>
    useFetchCreate('userViews')

export const useUserViewGet = (): FetchGet<FetchResponse<IGetResponse>, IGetViewParams> =>
    useFetchGet('userViews', { autoStart: false });