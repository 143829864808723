import { CSSProperties } from 'react';

const baseReportSectionStyle: CSSProperties = {
    border: '1px solid #F0F5FF',
    borderRadius: '12px',
};

export const getReportSectionStyle = (name: string): CSSProperties => name === 'welcome_message' ?
    { ...baseReportSectionStyle, maxWidth: '100%' } : baseReportSectionStyle

export const styleEclipses: CSSProperties = {
    width: '100%',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
}

export const tooltipStyle: CSSProperties = {
    backgroundColor: '#f5f5f5',
};

export const COLORS = {
    White: '#ffffff',
    BorderGrey: "#a5b5d4"
}
