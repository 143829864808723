import { Button, Col, Row, Space, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePaymentMethodId, usePlanId, usePaymentCreate } from '../../../../../hooks/stripe';
import ContentCard from '../../../../Common/ContentCard';
import Loading from '../../../../Common/Loading';
import PaymentItem from '../../../../Common/PaymentItem';
import styles from '../../index.module.less';
import { formatPrice } from '../SelectPlan/PlanRow';
import { setDataInStorage } from '../../../../../utils/storage';

interface ISelectPlan {
  promoCode: string | undefined;
  selectedPlan: string | null;
  selectedPaymentMethod: string | null;
  handleBack: () => void;
  discount: any;
}

const Confirm = ({
  selectedPlan,
  selectedPaymentMethod,
  handleBack,
  promoCode,
  discount,
}: ISelectPlan): JSX.Element => {
  const navigate = useNavigate();
  const planId = usePlanId();
  const paymentMethodId = usePaymentMethodId();
  const paymentCreate = usePaymentCreate();

  useEffect(() => {
    if (!selectedPaymentMethod) return;
    paymentMethodId.fetch(undefined, selectedPaymentMethod);
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (!selectedPlan) return;
    planId.fetch(undefined, selectedPlan);
  }, [selectedPlan]);

  const handleConfirm = () => {
    if (!selectedPlan || !selectedPaymentMethod) return;

    paymentCreate
      .fetch({
        pmId: selectedPaymentMethod,
        planId: selectedPlan,
        promoCode,
        isFreeTrial: !!planId?.data?.data.trial_period_days,
      })
      .then(() => {
        setDataInStorage('signUpData', {});
        navigate('/');
      })
      .then(() => {
        window.fbq('track', 'Purchase', {
          value: formatPrice((plan?.amount ?? 0) * ((100 - discount) / 100)) || 0,
          currency: 'USD',
          discount
        });

        if (!window.dataLayer) {
          window.dataLayer = [];
        }
        window.dataLayer.push({
          event: 'Purchase',
          value: formatPrice((plan?.amount ?? 0) * ((100 - discount) / 100)) || 0,
          currency: 'USD',
          discount
        });

        // Reddit Pixel
        window.rdt('track', 'Purchase', {
          value: formatPrice((plan?.amount ?? 0) * ((100 - discount) / 100)) || 0,
          currency: 'USD',
          discount
        });
      })
  };

  const plan = planId.data?.data;
  const displayInterval = plan?.interval === 'year' ? 'yr' : 'mo';

  return (
    <Space direction="vertical" size={20}>
      <ContentCard loading={planId.loading || paymentMethodId.loading}>
        <Loading visible={paymentCreate.loading} absolute />
        <Space direction="vertical" size={20}>
          {planId.data && (
            <Space direction="vertical" size={8}>
              <Typography.Title level={3}>Your Plan</Typography.Title>
              <div className={styles.row}>
                <Typography className="color-gray">
                  {plan?.nickname} {plan?.trial_period_days && `${plan?.trial_period_days} days`}
                </Typography>
                <div className="flex-row flex-align-center gap-12">
                  {!!promoCode && !plan?.trial_period_days && plan?.amount && (
                    <Typography.Title level={3} className={styles.price}>
                      {formatPrice(plan.amount * ((100 - discount) / 100))}/{displayInterval}
                    </Typography.Title>
                  )}

                  <Typography.Title
                    level={3}
                    className={clsx(styles.price, {
                      'line-through color-gray': promoCode && !plan?.trial_period_days,
                    })}
                  >
                    {plan && formatPrice(plan.amount || 0)}/{displayInterval}
                  </Typography.Title>
                </div>
              </div>
            </Space>
          )}
          {!!paymentMethodId.data?.data && (
            <Space direction="vertical" size={8}>
              <Typography.Title level={3}>Payment method</Typography.Title>
              <PaymentItem key={paymentMethodId.data?.data.id} card={paymentMethodId.data?.data?.card} />
            </Space>
          )}
        </Space>
      </ContentCard>
      <Row gutter={24}>
        <Col span={12}>
          <Button size="large" type="default" block onClick={handleBack} disabled={paymentCreate.loading}>
            Back
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="large"
            type="primary"
            block
            onClick={handleConfirm}
            loading={paymentCreate.loading}
            disabled={paymentCreate.loading}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

Confirm.defaultProps = {
  coupon: undefined,
};

export default Confirm;
