/* eslint-disable @typescript-eslint/ban-ts-comment */
import qs from 'querystring';
import { Alert, Button, Checkbox, Col, Form as AntdForm, Input, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth, SignUp as ISignUp } from '../../../../context/auth';
import { login, nmls, password } from '../../../../utils/inputRules';
import styles from '../index.module.less';
import Wrapper from '../index';
import { setDataInStorage, getDataInStorage } from '../../../../utils/storage';

const SignUpForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authorized, signUp, loading, error, clearResponseError } = useAuth();
  const [form] = useForm();

  useEffect(() => handleFocus(), []);

  useEffect(() => {
    if (authorized) {
      navigate('/setup', { replace: true });
    }
  }, [authorized]);

  useEffect(() => {
    clearResponseError();
  }, []);

  const handleSignUp = (values: ISignUp) => {
    const { ref } = qs.parse(location.search.replace('?', '')) as { [key: string]: string | undefined };

    setDataInStorage('signUpData', values);

    signUp({ ...values, ref });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const storedData = getDataInStorage('signUpData');
    const initialFormData = storedData || { email: '', nmlsId: '', password: '' };

    form.setFieldsValue(initialFormData);
  }, [form]);

  const handleFocus = () => {
    // const name = e?.target?.name ?? 'email'
    // if(!e?.target?.value) {
    //   form.setFields([
    //     {
    //       name,
    //       // @ts-ignore
    //       errors: [RULES[name][1]?.message],
    //     },
    //   ]);
    // }
  };

  const handleAlreadySignUp = () => {
    setDataInStorage('signUpData', {});
    navigate('/sign-in');
  };

  const handleNMLSChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');

    form.setFieldsValue({ nmlsId: numericValue });
  };

  return (
    <Space direction="vertical" size={24}>
      <AntdForm form={form} layout="vertical" onFinish={handleSignUp} className={styles.form} autoComplete="off">
        {error ? (
          <AntdForm.Item>
            <Alert onClose={clearResponseError} message={error} type="error" closable showIcon />
          </AntdForm.Item>
        ) : null}
        <AntdForm.Item name="email" label="Email" rules={login} className="label-gray">
          <Input
            size="large"
            name="email"
            placeholder="Enter Email"
            onFocus={handleFocus}
            autoFocus
            onKeyDown={handleKeyDown}
          />
        </AntdForm.Item>
        <AntdForm.Item
          name="password"
          label="Password"
          rules={password}
          extra="Min 8 characters"
          hasFeedback
          className="label-gray"
        >
          <Input.Password
            size="large"
            name="password"
            onFocus={handleFocus}
            placeholder="Enter Password"
            onKeyDown={handleKeyDown}
          />
        </AntdForm.Item>
        <AntdForm.Item name="nmlsId" label="NMLS" rules={nmls} className="label-gray">
          <Input
            size="large"
            name="nmlsId"
            onFocus={handleFocus}
            placeholder="Enter your NMLS number"
            onKeyDown={handleKeyDown}
            onChange={handleNMLSChange}
          />
        </AntdForm.Item>
        <AntdForm.Item name="terms" valuePropName="checked">
          <Checkbox className="color-gray checkbox-large">
            By clicking, I accept{' '}
            <Link to="https://mortgagemaker.ai/terms-of-service/" target="_blank">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link to="https://mortgagemaker.ai/privacy-policy/" target="_blank">
              Privacy Policy
            </Link>
          </Checkbox>
        </AntdForm.Item>
        <AntdForm.Item shouldUpdate noStyle>
          {({ getFieldsValue, getFieldsError }) => {
            const { terms, email, password: passwordValue, nmlsId } = getFieldsValue();
            const formIsComplete =
              terms && email && passwordValue && nmlsId && getFieldsError().every((field) => !field.errors.length);

            return (
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                    disabled={!formIsComplete}
                  >
                    Create Account
                  </Button>
                </Col>
              </Row>
            );
          }}
        </AntdForm.Item>
      </AntdForm>
      <div className={styles.textBox}>
        <Typography.Text className={styles.text}>
          Already have an account?{' '}
          <a onClick={handleAlreadySignUp} style={{ color: '#1E0C68', cursor: 'pointer' }}>
            Sign In
          </a>
        </Typography.Text>
      </div>
    </Space>
  );
};

const SignUp = (): JSX.Element => {
  const items: TabsProps['items'] = [
    {
      key: '0',
      label: '',
      children: (
        <Wrapper title="Sign Up" text="Get Mortgage Maker to manage your work">
          <SignUpForm />
        </Wrapper>
      ),
    },
    {
      key: '1',
      label: '',
    },
    {
      key: '2',
      label: '',
    },
    {
      key: '3',
      label: '',
    },
  ];

  return (
    <Tabs
      tabPosition="bottom"
      centered
      tabBarGutter={10}
      tabBarStyle={{ borderRadius: 4, height: 5 }}
      className="steps"
      items={items}
    />
  );
};

export default SignUp;
