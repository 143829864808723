import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useCurrentMarketRates } from '../../../hooks/reports';
import MarketRatesChart from '../../Common/MarketRatesChart';

const MarketRates = () => {
  const currentMarketRates = useCurrentMarketRates();
  const currentTreasury = useCurrentMarketRates();
  let interval: ReturnType<typeof setTimeout>;

  const getChartsData = () => {
    currentMarketRates.fetch({ years: 30 });

    currentTreasury.fetch({ years: 10 });
  };

  useEffect(() => {
    getChartsData();
    interval = setInterval(() => {
      getChartsData();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex-col gap-12">
      <Typography.Title level={1}>Markets</Typography.Title>
      <div className="flex-col gap-20">
        <MarketRatesChart
          title="UMBS 30 Year - 5.5% Coupon"
          data={currentMarketRates.data?.data}
          loading={currentMarketRates.loading}
          height={300}
          reversed={false}
        />
        <MarketRatesChart
          title="US 10 Year Treasury"
          data={currentTreasury.data?.data}
          loading={currentTreasury.loading}
          reversed
          height={300}
        />
      </div>
    </div>
  );
};

export default MarketRates;
